












































































import { mdiClose } from '@mdi/js';
import Vue from 'vue';
import LoginCard from '@/components/specific/LoginCard.vue';
import Log from '@/resources/plugins/Logger/Log';
import ServiceFactory from '@/services/ui/ServiceFactory';
import { DomainAuthMapper } from '@/store/modules/domain/auth';
import { UIWorkspaceMapper } from '@/store/modules/ui/workspace';

const AuthService = ServiceFactory.get('auth');

export default Vue.extend({
  name: 'Signup',

  components: {
    LoginCard,
  },

  data(): {
    dialog: boolean;
    dialogTitle: string;
    disabled: boolean;
    icons: {
      [key: string]: string;
    };
    loading: boolean;
  } {
    return {
      dialog: false,
      dialogTitle: '',
      disabled: false,
      icons: {
        mdiClose,
      },
      loading: false,
    };
  },
  computed: {
    ...UIWorkspaceMapper.mapState(['workspace']),
  },

  methods: {
    ...DomainAuthMapper.mapActions(['setToken', 'setUserAttributes', 'setUserAttributesSpecified']),

    async toSignup() {
      const self = this;
      try {
        self.loading = true;

        // ワンタイムセッションチェック
        const sessionId = sessionStorage.getItem('oneTimeSession') as string;

        const response = await AuthService.checkSession({
          onetimeSession: sessionId,
          workspaceId: this.workspace.workspaceId,
        });
        // ユーザー情報をストアにセット
        this.setUserAttributesSpecified(response);

        self.loading = false;
        self.$router.push('/signup/input');
      } catch (error: any) {
        Log.error(error);
        self.loading = false;

        switch (error.status) {
          case 401:
            this.dialog = true;
            this.dialogTitle = 'このURLは無効です';
            this.disabled = true;
            break;
          default:
        }
      }
    },
  },
});
